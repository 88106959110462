<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    // 判断移动端还是pc端
    _isMobile () {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    }
  },
  mounted: function () {
    if (this._isMobile()) {
      // 移动端首页路由
      window.location.href = 'http://m.srb-qin.topartsintermational.com'
    }
  },
}
</script>

<style lang="less">
body {
  margin: 0;
  padding: 0;
  height: 100%;
  letter-spacing: 1px;
  line-height: 24px;
  box-sizing: border-box;
}
body img {
  vertical-align: bottom;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
// 栅格
.row {
  display: flex;
  flex-wrap: wrap;
}
.col-2 {
  width: 16.6%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.3%;
}
.col-5 {
  width: 41.6%;
}
.col-6 {
  width: 50%;
}
.col-12 {
  width: 100%;
}

// 调整全局dialog header
.dialog .el-dialog__header {
  padding: 0;
}
.dialog .el-dialog__body {
  padding: 0;
}
// 调整culture页面的轮播按钮颜色
.culture .el-carousel__arrow {
  background: #053154;
}
// 修改dialog动画
.qw-dialog .el-dialog__wrapper {
  transition-duration: 0.3s;
}
.qw-dialog .dialog-fade-enter-active {
  animation: none !important;
}
.qw-dialog .dialog-fade-leave-active {
  transition-duration: 0.15s !important;
  animation: none !important;
}

.qw-dialog .dialog-fade-enter-active .el-dialog,
.dialog-fade-leave-active .el-dialog {
  animation-fill-mode: forwards;
}

.qw-dialog .dialog-fade-enter-active .el-dialog {
  animation-duration: 0.3s;
  animation-name: anim-open;
  animation-timing-function: cubic-bezier(0.6, 0, 0.4, 1);
}

.qw-dialog .dialog-fade-leave-active .el-dialog {
  animation-duration: 0.3s;
  animation-name: anim-close;
}

@keyframes anim-open {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 1);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

@keyframes anim-close {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale3d(0.5, 0.5, 1);
  }
}
</style>
